import { endOfMonth } from 'date-fns'

type IsListingUniqueSubmissionParams = {
  listingId: string
  listingLeadSubmissionIdsArrayAsString?: string
}

export function isListingUniqueSubmission({
  listingId,
  listingLeadSubmissionIdsArrayAsString,
}: IsListingUniqueSubmissionParams) {
  if (!listingLeadSubmissionIdsArrayAsString) {
    return true
  }

  try {
    return !JSON.parse(listingLeadSubmissionIdsArrayAsString).includes(
      listingId
    )
  } catch (e) {
    return true
  }
}

type createUniqueSubmissionCookieValueParams = {
  listingLeadSubmissionIdsArrayAsString?: string
  listingId?: string
}

export function createUniqueSubmissionCookieValue({
  listingLeadSubmissionIdsArrayAsString,
  listingId,
}: createUniqueSubmissionCookieValueParams): string {
  if (!listingLeadSubmissionIdsArrayAsString) {
    return '[]'
  }

  if (!listingId) {
    return listingLeadSubmissionIdsArrayAsString
  }

  const isUniqueSubmission = isListingUniqueSubmission({
    listingId,
    listingLeadSubmissionIdsArrayAsString,
  })

  if (!isUniqueSubmission) {
    return listingLeadSubmissionIdsArrayAsString
  }

  try {
    const oldIds = JSON.parse(listingLeadSubmissionIdsArrayAsString)
    const leadsRequestedWithNewListingID = oldIds || []
    leadsRequestedWithNewListingID.push(listingId)

    return JSON.stringify(leadsRequestedWithNewListingID)
  } catch (e) {
    return '[]'
  }
}

// last day of the month
export const getListingLeadSubmissionIdsCookieExpirationTime = () =>
  endOfMonth(new Date())

export const UNIQUE_LEAD_SUBMISSION_COOKIE_NAME = 'listingLeadSubmissionIds'
