import type { Tracker } from '../tracker'
import type { Dispatched, Subscriptions } from '../utils/event-bus'
import type { TrackerConfig } from './tracker'

export interface EventObject {
  [x: string]: any
}

declare global {
  interface Window {
    dataLayer: EventObject[]
    eventTracker: Tracker
    eventTrackerSettings?: TrackerConfig
    google_tag_manager?: any
    hit_id?: string
    gaGlobal?: {
      vid?: string
    }
    rentpathEventBus?: {
      subscriptions: Subscriptions
      dispatchedSubscriptions: Dispatched
      dispatch: (eventType: string) => void
      subscribe: (eventType: string, callback: (args: unknown) => void) => void
    }
    rentpathTracker: typeof Tracker
  }
}
