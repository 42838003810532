/* eslint-disable */
// @ts-ignore The GTM event type isn't typed
type Event = any
/* eslint-enable */

// Our event tracker emits every event twice;
// to avoid duplicating events in Rift, we need to dedupe without
// altering the expected behavior of the existing tracker
const trackedEvents = new Set()

function mapTrackingEventToRiftEvent(event: string, device: string) {
  switch (event) {
    case 'impression':
    case 'click':
      return event
    case 'scroll': {
      if (device == 'mobile') {
        return 'swipe'
      }
    }
    default:
      throw new Error('Unhandled event type')
  }
}

function mapScreenTypeToRiftType(screen: string) {
  switch (screen) {
    case 'desktop':
      return 'large'
    case 'tablet':
      return 'medium'
    case 'mobile':
      return 'small'
  }
}

function encodeRiftEvent(e: Event) {
  const encoded = Object.entries(e)
    .map(function ([key, value]) {
      return `${key}=${value}`
    })
    .join('&')

  return encoded
}

function sendEvent(event: Event): void {
  const eventId = event.id

  delete event.id

  const encoded = encodeRiftEvent(event)

  if (trackedEvents.has(eventId)) {
    throw new Error('Skipping duplicate event')
  } else {
    trackedEvents.add(eventId)
  }

  void fetch(`https://www.redfin.com/rift?${encoded}`, {
    mode: 'no-cors',
  }).catch(function (_err) {
    // Error related to sending the event - noop
    trackedEvents.delete(eventId)
  })
}

export function emitRiftEvent(event: Event): void {
  try {
    if (!event['gtm.uniqueEventId']) {
      return
    }

    const params = {
      id: event['gtm.uniqueEventId'],
      ev: 'external_web',
      ts: Date.now(),
      p: event.page || 'unknown',
      s: event.event_category || 'unknown',
      t: event.event_label || 'unknown',
      a: mapTrackingEventToRiftEvent(event.action, event.screen_type),
      sr: mapScreenTypeToRiftType(event.screen_type),
      rf: window.soUserId || 'unknown',
    }

    sendEvent(params)
  } catch (_err) {
    // Error related to mapping the event - noop
  }
}

export function emitStartEvent(experimentId: string, variantId: string): void {
  try {
    const eventKey = `${experimentId}-${variantId}-start`

    const params = {
      id: eventKey,
      ev: 'external_web',
      ts: Date.now(),
      p: 'ref_expt',
      s: experimentId,
      t: variantId,
      a: 'impression',
      rf: window.soUserId || 'unknown',
    }

    sendEvent(params)
  } catch (_err) {
    // Error related to mapping the event - noop
  }
}
