export interface TrackerState {
  initialPageview: number
}

export interface DeviceParserConfig {
  defaultType?: 'desktop' | 'tablet' | 'mobile'
  enabledTypes?: {
    desktop?: boolean
    mobile?: boolean
    tablet?: boolean
  }
}

export interface EcommerceParserConfig {
  campaignKey?: string
  sessionKey?: string
}

export interface SessionParserConfig {
  visitorKey?: string
  sessionKey?: string
  visitorAge?: number
  sessionAge?: number
}

export type CanTrack = (node: Node) => boolean

export type ConfigType<T, K> = T extends K ? K : T

export type ClientParse =
  | 'browser'
  | ['device', DeviceParserConfig]
  | 'device'
  | ['ecommerce', EcommerceParserConfig]
  | 'element'
  | 'event'
  | ['session', SessionParserConfig]
  | 'session'
  | 'tagmanager'

export type TrackerEvent =
  | 'click'
  | 'event'
  | 'focus'
  | 'form'
  | 'select'
  | 'view'

export interface GtmConfig {
  gtmId?: string
  gtmAuth?: (() => string) | string
  gtmPreview?: (() => string) | string
  trackCallback?: (data: object) => void
}

export interface Action {
  action?: string
}

export interface TrackerConfig extends GtmConfig {
  /* unique identifier for app */
  arch?: string
  trackers: TrackerEvent[]
  clientParsers?: ClientParse[]
  useOptimizedTracking?: boolean
}

export type PartialConfig = Pick<
  TrackerConfig,
  Extract<keyof TrackerConfig, 'trackers' | 'clientParsers'>
>

export interface BrowserParser {
  browser?: string
  browser_size?: string
  browser_version?: string
}

export interface DeviceParser {
  screen_type?: string
  screen_resolution?: string
  operating_system?: string
  operating_system_version?: string
}

export interface SessionParser {
  visit?: string
  visitor?: string
  session_id?: string
}

export interface EventParser {
  event?: MouseEvent | string
  x_coordinate?: string
  y_coordinate?: string
}

export interface ElementParser {
  element?: HTMLImageElement | HTMLInputElement | HTMLSelectElement
  tagPrefix?: string
  listing_id?: string
  revenue?: string
  selection?: string
  tpl_source?: string
  cil?: string
  nv_rpl?: string
}

export interface TagManagerParser {
  item?: string
  section?: string
  event_action?: string
  event_label?: string
  event_category?: string
}

export interface EcommerceParser {
  transactionId?: string
  transactionAffiliation?: string
  transactionAdjustedTotal?: string
  transactionTotal?: string
  uniqueSubmission?: string
  transactionProducts?: string
}

export interface Misc {
  arch?: string
  eventCallback?: () => void
  initialPageview?: number
}

export type AllClientParsers = Action &
  BrowserParser &
  DeviceParser &
  SessionParser &
  EventParser &
  ElementParser &
  EcommerceParser &
  TagManagerParser &
  Misc
