'use client'

import { yieldOrContinue } from 'main-thread-scheduling'
import { useEffect } from 'react'
import {
  type Metric,
  type INPMetricWithAttribution,
  onLCP,
  onCLS,
  onFCP,
  onTTFB,
} from 'web-vitals'

import { onINP } from 'web-vitals/attribution'
import { recordMetric } from './utils/record-metric'
import type { AnalyticsMeta } from './utils/get-analytics-meta'
import { trailingSlash } from '@brand/config/next'

/* eslint-disable */
const logMetric =
  process.env.APPLICATION_ENVIRONMENT !== 'production'
    ? console.log
    : () => {
        /* No-Op */
      }
/* eslint-enable */

type WebVitalsProps = {
  route: string
  analyticsEndpoint?: string
  variant: AnalyticsMeta['variant']
  device: AnalyticsMeta['device']
  deviceType: AnalyticsMeta['deviceType']
}

const DEFAULT_ENDPOINT = trailingSlash ? '/api/analytics/' : '/api/analytics'

async function handleGenericVital(
  route: WebVitalsProps['route'],
  analyticsEndpoint: string,
  meta: {
    variant: WebVitalsProps['variant']
    device: WebVitalsProps['device']
    deviceType: WebVitalsProps['deviceType']
  },
  metric: Metric
) {
  await yieldOrContinue('idle')

  logMetric(metric)

  await yieldOrContinue('idle')
  recordMetric(
    JSON.stringify({
      name: metric.name,
      value: metric.value,
      rating: metric.rating,
      navigationType: metric.navigationType,
      id: metric.id,
      route,
      device: meta.device,
      variant: meta.variant,
    }),
    analyticsEndpoint
  )
}

async function handleINPWithAttribution(
  route: WebVitalsProps['route'],
  analyticsEndpoint: string,
  meta: {
    variant: WebVitalsProps['variant']
    device: WebVitalsProps['device']
    deviceType: WebVitalsProps['deviceType']
  },
  metric: INPMetricWithAttribution
) {
  await yieldOrContinue('idle')

  logMetric(metric)

  await yieldOrContinue('idle')

  recordMetric(
    JSON.stringify({
      name: metric.name,
      value: metric.value,
      rating: metric.rating,
      navigationType: metric.navigationType,
      id: metric.id,
      attributionTarget: metric.attribution.interactionTarget || 'unknown',
      attributionInteraction: metric.attribution.interactionType || 'unknown',
      loadState: metric.attribution.loadState,
      processing_duration: metric.attribution.processingDuration,
      input_delay: metric.attribution.inputDelay,
      presentation_delay: metric.attribution.presentationDelay,
      route,
      connectionType: window.navigator?.connection?.effectiveType ?? 'unknown',
      device: meta.device,
      device_type: meta.deviceType,
      variant: meta.variant,
    }),
    analyticsEndpoint
  )
}

export function WebVitals({
  route,
  analyticsEndpoint = DEFAULT_ENDPOINT,
  variant,
  device,
  deviceType,
}: WebVitalsProps) {
  useEffect(
    function () {
      const reportVital = handleGenericVital.bind(
        undefined,
        route,
        analyticsEndpoint,
        {
          device,
          deviceType,
          variant,
        }
      )

      const reportINP = handleINPWithAttribution.bind(
        undefined,
        route,
        analyticsEndpoint,
        {
          device,
          deviceType,
          variant,
        }
      )

      onCLS(reportVital)
      onLCP(reportVital)
      onFCP(reportVital)
      onTTFB(reportVital)
      onINP(reportINP, { reportAllChanges: true })
    },
    [route, analyticsEndpoint, device, deviceType, variant]
  )

  return null
}
