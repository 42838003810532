import type { TrackerConfig } from '../types/tracker'

export function generateGtmScript(c: TrackerConfig): HTMLScriptElement {
  const script = document.createElement('script')
  script.async = true
  script.src = generateGtmScriptUrl(c)

  return script
}

export function generateGtmScriptUrl(c: TrackerConfig): string {
  const authNormalize =
    typeof c.gtmAuth === 'function' ? c.gtmAuth() : c.gtmAuth
  const gtmPreviewNormalize =
    typeof c.gtmPreview === 'function' ? c.gtmPreview() : c.gtmPreview

  return `https://www.googletagmanager.com/gtm.js?id=${c.gtmId}&gtm_auth=${authNormalize}&gtm_preview=${gtmPreviewNormalize}&gtm_cookies_win=x`
}
