// eslint-disable-next-line require-await
module.exports = async function redirects() {
  return [
    {
      source: '/list/',
      destination: '/list-a-property-for-rent/',
      permanent: true,
    },
    {
      source: `/legal/privacy/`,
      destination: 'https://solutions.rent.com/privacy-policy/',
      permanent: true,
    },
    {
      source: '/sitemap/:state/:city/',
      destination: '/sitemap/:state/:city/apartments/',
      permanent: true,
    },
    {
      source: `/:propertyType/District-Of-Columbia`,
      destination: '/:propertyType/District-of-Columbia/Washington',
      permanent: true,
    },
    {
      source: `/schools/:state/districts/:schoolDistrict`,
      destination: '/apartments/schools/:state/districts/:schoolDistrict',
      permanent: true,
    },
    {
      source: `/schools/:state/:city/:schoolDistrict`,
      destination: '/apartments/schools/:state/:city/:schoolDistrict',
      permanent: true,
    },
    {
      source: `/:propertyType(apartments|condos|townhomes|houses)/:state`,
      destination: '/:state',
      permanent: true,
    },
    {
      source: `/:propertyType(apartments|condos|townhomes|houses)/:state/:city/:name/:id/Reviews/new`,
      destination:
        '/:propertyType(apartments|condos|townhomes|houses)/:state/:city/:name/:id/review',
      permanent: true,
    },
    {
      source: `/:propertyType(apartments|condos|townhomes|houses)/:state/:city/5-beds-1z141la/`,
      destination:
        '/:propertyType(apartments|condos|townhomes|houses)/:state/:city/4-beds-1z141to/',
      permanent: true,
    },
    ...[
      '/p/:propertyTypes/:state/:location/:poi',
      `/:propertyTypes/schools/:state/districts/:schoolDistrict`,
      `/:propertyTypes/schools/:state/:location/:school`,
      `/neighborhoods/:propertyTypes/:state/:location/:hood`,
      `/:propertyTypes/:state/:location`,
      `/zip/:zipCode-:propertyTypes-For-Rent`,
      `/:propertyTypes/counties/:state/:county`,
    ]
      .map((pathWithoutRefinementsSlug) => {
        return [
          {
            source: `${pathWithoutRefinementsSlug}/cheap-apartments-for-rent-4la/`,
            destination: `${pathWithoutRefinementsSlug}/cheap/`,
            permanent: true,
          },
          {
            source: `${pathWithoutRefinementsSlug}/luxury-apartments-for-rent-4l9/`,
            destination: `${pathWithoutRefinementsSlug}/luxury/`,
            permanent: true,
          },
        ]
      })
      .flat(),
  ]
}
