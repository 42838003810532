function logMethod<T extends object>(
  target: T,
  propertyKey: string,
  propertyDescriptor?: PropertyDescriptor
): void {
  if (!(performance || window.performance)) {
    return
  }

  const Performance = performance || window.performance

  if (!propertyDescriptor) {
    const attemptGetPropertyDescriptor = Object.getOwnPropertyDescriptor(
      target,
      propertyKey
    )

    propertyDescriptor = attemptGetPropertyDescriptor

    if (!propertyDescriptor) {
      return
    }
  }

  // copy original method
  const originalMethod = propertyDescriptor.value

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propertyDescriptor.value = function <U extends any[]>(...args: U) {
    const parametersAsString = []
    for (let i = 0; i < args.length; i++) {
      parametersAsString.push(args[i])
    }
    parametersAsString.join(',')

    Performance.mark(`${propertyKey}('${parametersAsString}')-begin`)
    const result = originalMethod.apply(this, args)
    Performance.mark(`${propertyKey}('${parametersAsString}')-end`)

    Performance.measure(
      `${propertyKey}('${parametersAsString}') => ${JSON.stringify(result)}`,
      `${propertyKey}('${parametersAsString}')-begin`,
      `${propertyKey}('${parametersAsString}')-end`
    )

    return result
  }
}

export function logMethodPerformance(logConsole?: boolean): typeof logMethod {
  if (logConsole && PerformanceObserver) {
    const obs = new PerformanceObserver((list) => {
      const entries = list.getEntries()

      for (let i = 0; i < entries.length; i++) {
        // eslint-disable-next-line no-console
        console.log(`[ ${entries[i].duration}ms ] ${entries[i].name}`)
      }
    })

    obs.observe({ entryTypes: ['measure'] })
  }

  return logMethod
}
