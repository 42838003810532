'use client'

import { useEffect } from 'react'
import type { RequestData } from '../request-data/request-data.const'
import type { AbTestingData } from '../ab-testing/types'

export function LogRequestData(props: {
  requestData: RequestData
  abTestingData?: AbTestingData
  pageData?: unknown
}) {
  useEffect(() => {
    /* eslint-disable no-console */
    console.groupCollapsed(
      '%c Unified Web Page Props ',
      'color: #fff; font-weight: bold; background-color: #0000ff'
    )
    console.groupCollapsed('requestData')
    console.log(props.requestData)
    console.groupEnd()

    if (props.abTestingData) {
      console.groupCollapsed('abTestingData')
      console.log(props.abTestingData)
      console.groupEnd()
    }

    if (props.pageData) {
      console.groupCollapsed('pageData')
      console.log(props.pageData)
      console.groupEnd()
    }
    console.groupEnd()
  }, [props.requestData, props.abTestingData, props.pageData])

  return null
}
