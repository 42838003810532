'use client'

import { useEffect, useRef, useState } from 'react'
import type { FooterProps } from './footer.types'
import styles from './footer-skeleton.module.css'
import dynamic from 'next/dynamic'

const Footer = dynamic(() =>
  import('@brand/slots/footer/footer').then((mod) => mod.Footer)
)

export function LazyFooter(props: FooterProps & { lazy?: boolean }) {
  const { lazy } = props
  const ref = useRef<HTMLDivElement>(null)
  const [hasIntersected, setHasIntersected] = useState(!lazy)

  useEffect(() => {
    if (lazy) {
      if (!ref.current) {
        return
      }

      const skeleton = ref.current
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0]

          if (entry.isIntersecting && !hasIntersected) {
            observer.unobserve(skeleton)
            setHasIntersected(true)
          }
        },
        {
          threshold: 0,
          rootMargin: '20% 0% 20% 0%',
        }
      )

      observer.observe(skeleton)

      return () => {
        observer.disconnect()
      }
    }
  }, [hasIntersected, lazy])

  return hasIntersected || !lazy ? (
    <Footer {...props} />
  ) : (
    <footer
      data-tid="footer-skeleton"
      className={styles.footerSkeleton}
      ref={ref}
    />
  )
}
